a, abbr, acronym, address, applet, big, blockquote, body, caption, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
  box-sizing: border-box;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a, abbr, acronym, address, applet, big, blockquote, body {
  vertical-align: baseline;
}

a, abbr, acronym, address, applet, big, blockquote, body, caption {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

cite, code, dd, del, dfn, div, dl, dt, em, fieldset, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup {
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

table {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

tbody {
  vertical-align: baseline;
}

tbody, td {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

tfoot {
  vertical-align: baseline;
}

tfoot, th, thead, tr, tt, ul, var {
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
}

thead, tr, tt, ul, var {
  vertical-align: baseline;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}

caption, td, th {
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
}

a img {
  border: none;
}

a {
  color: #222;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  display: block;
}

[hidden], audio:not([controls]) {
  display: none;
}

* {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  background: none;
  outline: none;
  text-decoration: none;
}

input[type="search"] {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

body {
  background-color: #121220;
  width: 7.5rem;
  margin: 0 auto;
  font-family: Noto Sans SC;
}

.slide-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.08rem;
  padding: 0 .3rem;
  display: flex;
}

.slide-header > img:first-child {
  width: .4rem;
  height: .4rem;
}

.slide-header > .btn-close {
  width: .34rem;
  height: .34rem;
}

#sidebar {
  background-color: #fff;
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -1000px;
  overflow: hidden;
}

.type-list {
  width: 100%;
  padding: 0 .3rem;
}

.header-top {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.header-top > a {
  width: 100%;
  height: 1.8rem;
  display: block;
}

.header-top .head-img {
  width: 100%;
  height: 1.8rem;
}

.header-top .icon2 {
  width: 1.26rem;
  height: 1rem;
  position: absolute;
  top: .4rem;
  right: 1.65rem;
}

.header-top .icon2 > img {
  width: 100%;
}

.header-top .icon1 {
  width: 1.26rem;
  height: 1rem;
  position: absolute;
  top: .4rem;
  right: .28rem;
}

.header-top .icon1 > img {
  width: 100%;
}

.header-top .icon3 {
  width: .8rem;
  height: .8rem;
  position: absolute;
  top: .44rem;
  right: .28rem;
}

.header-top .icon3 > img {
  width: 100%;
}

.card {
  background-color: #1f2539;
  border-radius: .2rem;
  width: 6.94rem;
  margin: .3rem auto;
  padding: .2rem;
}

.card .item-r {
  flex-direction: row;
  width: 100%;
  height: 4.32rem;
  margin-bottom: .2rem;
  display: flex;
}

.card .item-r .lt {
  border-radius: .2rem;
  width: 4.32rem;
  height: 4.32rem;
  margin-left: .12rem;
  position: relative;
}

.card .item-r .lt > a {
  display: block;
  position: relative;
}

.card .item-r .lt > a:after {
  content: "";
  background: url("hotn.ede5f6e6.png") center / 100% no-repeat;
  width: .72rem;
  height: .54rem;
  position: absolute;
  top: 0;
  left: -.1rem;
}

.card .item-r .lt p > .txt {
  height: .66rem;
  font-size: .28rem;
  line-height: .66rem;
}

.card .item-r .lt img {
  border-radius: 10px;
  width: 4.32rem;
  height: 4.32rem;
}

.card .item-r .rt {
  flex-flow: wrap;
  width: 2.1rem;
  height: 2.1rem;
  display: flex;
}

.card .item-r .rt img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.card .item-r .rt > a {
  border-radius: 10px;
  width: 2.1rem;
  height: 2.1rem;
  margin-bottom: .2rem;
  display: block;
  position: relative;
}

.card .item-r .rt > a:after {
  content: "";
  background: url("hotn.ede5f6e6.png") center / 100% no-repeat;
  width: .72rem;
  height: .54rem;
  position: absolute;
  top: 0;
  left: -.1rem;
}

.card .item-r p {
  background-color: #00000080;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: .1rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card .item-r p > .txt {
  color: #fff;
  height: .36rem;
  font-size: .2rem;
  line-height: .36rem;
}

.card .hotn {
  position: relative;
}

.card .hotn:after {
  content: "";
  background: url("hotn.ede5f6e6.png") center / 100% no-repeat;
  width: .72rem;
  height: .54rem;
  position: absolute;
  top: 0;
  left: -.1rem;
}

.box-game {
  flex-flow: wrap;
  width: 6.94rem;
  margin: 0 auto;
  display: flex;
}

.box-game p {
  background-color: #00000080;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.box-game p > .txt {
  color: #fff;
  height: .36rem;
  font-size: .2rem;
  line-height: .36rem;
}

.box-game .item {
  width: 2.1rem;
  height: 2.1rem;
  margin-bottom: .2rem;
  margin-left: .1rem;
  position: relative;
}

.box-game .item:nth-child(3n+1) {
  margin-left: 0;
}

.box-game .item p {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: .44rem;
  margin: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.box-game .item a {
  width: 2.1rem;
  height: 2.1rem;
  display: block;
}

.box-game .item img {
  border-radius: .2rem;
  width: 2.1rem;
  height: 2.1rem;
  overflow: hidden;
}

.title {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: .4rem;
  margin: 0 auto .3rem;
  display: flex;
}

.title .lf {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.title .line {
  background-color: #fff;
  border-radius: .1rem;
  width: .1rem;
  height: .4rem;
  margin-right: .08rem;
}

.title span {
  color: #fff;
  font-size: .4rem;
  font-weight: bold;
}

.title a {
  align-items: center;
  height: .4rem;
  display: flex;
}

.title .img {
  width: .36rem;
  height: .36rem;
}

.box1 {
  width: 6.94rem;
  margin: .3rem auto 0;
}

.box-list1 {
  flex-flow: wrap;
  width: 100%;
  display: flex;
}

.box-list1 .items {
  width: 3.38rem;
  margin-bottom: .2rem;
  margin-left: .18rem;
}

.box-list1 .items:nth-child(odd) {
  margin-left: 0;
}

.box-list1 .items > a {
  display: block;
}

.box-list1 .items .items-img {
  background: url("default.685270e4.png") center / 100% no-repeat;
  border-radius: .2rem;
  width: 3.38rem;
  height: 2rem;
  overflow: hidden;
}

.box-list1 .items p {
  text-align: center;
  color: #292f52;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  height: .4rem;
  font-size: .28rem;
  line-height: .4rem;
  overflow: hidden;
}

.footer {
  box-shadow: none;
  background-color: #1f2539;
  width: 100%;
  margin: .3rem auto 0;
  padding-top: .4rem;
}

.footer a {
  margin: 0 auto;
  display: flex;
}

.ft-img {
  width: 6.94rem;
  height: 100%;
  margin: 0 auto;
}

.footer .ft-txt {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-bottom: .2rem;
  font-size: .28rem;
  display: block;
}

.ft-nav {
  background-color: #121220;
  border-radius: .2rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 6.94rem;
  height: 1.04rem;
  margin: 0 auto .3rem;
  display: flex;
}

.ft-nav a {
  color: #fff;
  text-align: center;
  font-size: .24rem;
  font-weight: bold;
  text-decoration: underline;
}

.ft-nav span {
  background-color: #fff;
  width: .01rem;
  height: .4rem;
  overflow: hidden;
}

.empty {
  width: 100%;
  height: .4rem;
}

a {
  text-decoration: none;
}

.adv {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.adv p {
  text-align: center;
  color: #88af69;
  width: 100%;
  margin: 20px 0;
  font-size: 20px;
}

/*# sourceMappingURL=search.bb17fdcc.css.map */
